import React from "react";
import styled from "styled-components";
import Container from "../Container";

const StyledTestimonial = styled.div`
  padding: 25px 0;
  text-align: center;

  @media only screen and (min-width: 740px) {
    padding: 100px 0;
    border-bottom: 1px solid #e4e4e4;
    text-align: left;
  }

  @media only screen and (min-width: 920px) {
  }

  .testimonial-holder {
    @media only screen and (min-width: 740px) {
      display: flex;
      padding: 0 40px;
    }

    @media only screen and (min-width: 920px) {
    }
  }

  .avatar-holder {
    @media only screen and (min-width: 740px) {
      padding-right: 20px;
    }

    @media only screen and (min-width: 920px) {
    }
  }

  .copy-holder {
    @media only screen and (min-width: 740px) {
      padding-left: 20px;
    }

    @media only screen and (min-width: 920px) {
    }
  }

  .testimonial-copy {
    font-size: 16px;
    line-height: 24px;

    @media only screen and (min-width: 740px) {
      font-size: 18px;
      line-height: 30px;
    }

    @media only screen and (min-width: 920px) {
    }
  }

  .author {
    font-style: normal;
    color: rgb(60, 162, 230);
    margin-bottom: 3px;
  }

  .date {
    font-style: italic;
    color: rgb(170, 170, 170);
    margin: 0px;
    padding: 0px;
  }
`;

const Testimonial = ({ avatar, testimonialCopy, author, date }) => {
  return (
    <Container>
      <StyledTestimonial>
        <div className="testimonial-holder">
          <div className="avatar-holder">
            <img src={avatar} alt="Avatar" />
          </div>
          <div className="copy-holder">
            <p className="testimonial-copy">“{testimonialCopy}”</p>
            <p className="author">- {author}</p>
            <span className="date">{date}</span>
          </div>
        </div>
      </StyledTestimonial>
    </Container>
  );
};

export default Testimonial;
