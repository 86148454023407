import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import IntroCopy from "../components/IntroCopy";
import Testimonial from "../components/Testimonial";
import ImageGallery from "../components/ImageGallery";

import avatar1 from "../images/testimonials/vector-avatar-1.jpg";
import avatar2 from "../images/testimonials/vector-avatar-2.jpg";
import avatar3 from "../images/testimonials/vector-avatar-3.jpg";
import avatar4 from "../images/testimonials/vector-avatar-4.jpg";
import avatar5 from "../images/testimonials/vector-avatar-5.jpg";

const TestimonialsPage = ({ data }) => (
  <Layout>
    <main>
      <SEO title="Testimonials" />
      <IntroCopy
        headline="What are people saying about us?"
        body="We take great care in our work and look to provide the highest standards for our customers, but don't take our word for it. Heres what our customers have to say about us."
        topPadding
      />
      <ImageGallery
        images={data.images.nodes.filter(image => image.name === "hero")}
      />
      <Testimonial
        avatar={avatar4}
        testimonialCopy="Sam and his team are professional and efficient, completing the agreed work on estimate and to a high standard. I highly recommend Agace Brothers for domestic roofing works/repairs."
        author="Mr Nav S"
        date="March, 2020"
      />
      <Testimonial
        avatar={avatar5}
        testimonialCopy="Agace Bros replaced my garage roof a few weeks ago. They were an extremely polite, helpful and pleasant team and the  whole job was carried out with great efficiency. I would be very happy to employ them again for any similar work."
        author="Mrs Slade"
        date="February, 2020"
      />
      <Testimonial
        avatar={avatar3}
        testimonialCopy="I would highly recommend Sam and his team for your roofing requirements. They were very professional and polite, did an amazing job and left everywhere clean and tidy."
        author="Mrs Dunn"
        date="January, 2020"
      />
      <Testimonial
        avatar={avatar4}
        testimonialCopy="Thank you all for doing such a fantastic job. I am delighted with the job you have done. The best roofing work we have had done in 30 years of house building!! Thank you."
        author="Mr Prothero"
        date="April, 2019"
      />
      <Testimonial
        avatar={avatar1}
        testimonialCopy="Agace Brothers were recommended to us by friends and we are absolutely delighted with the work done in fixing our leaking roof extension, with better and more suitable tiles. From the beginning of the process, i.e. our first enquiry, right through to completion they were very efficient and professional. They worked hard, were polite and a pleasure to have around. So Agace Brothers come highly recommended."
        author="Mr Harper"
        date="February, 2019"
      />
      <Testimonial
        avatar={avatar2}
        testimonialCopy="Just to say again how pleased I am with the new roof, I am a perfectionist and difficult to please - the roof is perfect!"
        author="Mrs Mansell"
        date="November, 2018"
      />
      <Testimonial
        avatar={avatar4}
        testimonialCopy="The standard of work was to the highest degree possible, attention to detail and neatness was second to none and I can say that with confidence having run my own building company for 42 years. Leslie and I are delighted with the finished job and have had numerous comments from friends and neighbours saying how good everything looks. We would have absolutely no hesitation whatsoever in recommending your company to anyone considering having roof work carried out to their property."
        author="Mr Huggard"
        date="January, 2018"
      />
      <Testimonial
        avatar={avatar1}
        testimonialCopy="Just to say what a super job you and Jamie, with the assistance of Jason, made of the new roof on my garage and lean to. It all looks great and illustrates the care that you take with a job and attention to detail. Many Thanks, I am highly satisfied."
        author="Mr H"
        date="March, 2017"
      />
      <Testimonial
        avatar={avatar3}
        testimonialCopy="You were recommended by a friend and we have no hesitation in highly recommending you others. You were pleasant, professional and prompt - with estimates, work and invoice. You were considerate and kept us informed of your work. You left the site clean and tidy.  Many thanks."
        author="Mrs Birtwistle"
        date="November, 2016"
      />
      <Testimonial
        avatar={avatar5}
        testimonialCopy="Both my husband and I felt that your professionalism, politeness and respect, as well as your efficiency in your work were exemplary. The end results prove that to anyone. You came promptly to see the leaks and the fascia damage; forwarded the estimate without any delay, kept me informed about the start of the work. You tidied up afterwards everything. 10/10!"
        author="Mrs King"
        date="April, 2016"
      />
      <Testimonial
        avatar={avatar4}
        testimonialCopy="You and your team did a wonderful job and you were careful, just as you said you would be: I am really pleased with the work and with the way you all worked so hard. Isn’t it refreshing in this day and age that I can say such things about ‘roofers’. Pride in ones work is not dead after all!"
        author="Father Lagario"
        date="Feburary, 2016"
      />
      <Testimonial
        avatar={avatar1}
        testimonialCopy="The new roof and the repointing has totally refreshed my property which was built in the 1890's and I have had very positive comments from my neighbours."
        author="Mr Bradley"
        date="March, 2014"
      />
      <Testimonial
        avatar={avatar3}
        testimonialCopy="Without exception they were considerate, friendly and extremely hardworking and left the site cleaner than they found it."
        author="Mr & Mrs Thomas"
        date="September, 2015"
      />
    </main>
  </Layout>
);

export const query = graphql`
  query TestimonalsImages {
    images: allFile(filter: { relativeDirectory: { eq: "testimonials" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default TestimonialsPage;
